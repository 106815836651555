/* global window */
import General from '../General';

class Analytics {
	static sendPageView(page, title) {
		if (window.gtag) {
			window.gtag('event', 'page_view', {
			page_title: title,
			page_path: page,
			});
		}
	}

	static sendPageViewFromRouter(location, organizationName) {
		const title = General.getPageTitle(location.pathname, organizationName);

		Analytics.sendPageView(location.pathname, title);
	}

	static sendEvent(name, category, label = '') {
		if (window.gtag) {
			window.gtag('event', name, {
				'event_category': category,
				'event_label': label
			});
		}
	}
}

export default Analytics;
